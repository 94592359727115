import moment from "moment";

import { CountryTypes, DirectoryTypes, validationTypeEnum } from "@/components/privatePages/RequestService/common";
export type EnvVariables = typeof import("@/helpers/envs");

export enum QueryKeys {
  CONFIG = "config",
  USER = "user",
  APPLICATIONS = "APPLICATIONS",
  APP = "APP",
  ACCOUNT = "ACCOUNT",
  ACCOUNTS = "ACCOUNTS",
  PRODUCTS = "PRODUCTS",
  ANALYTICS = "analytics",
  RESPONSE_AUDITS = "RESPONSE_AUDITS",
  RESPONSE_AUDIT = "RESPONSE_AUDIT",
  RESPONSE_SETTINGS = "RESPONSE_SETTINGS",
  LEVENSHTEIN_DISTANCE = "LEVENSHTEIN_DISTANCE",
  ADMIN_USERS = "ADMIN_USERS",
  REQUEST_AUDITS = "REQUEST_AUDITS",
  REQUEST_AUDIT_BATCHES = "REQUEST_AUDIT_BATCHES",
  REQUEST_AUDIT = "REQUEST_AUDIT",
  REQUEST_MI_REPORT = "REQUEST_MI_REPORT",
  REQUEST_MI_REPORT_EMAILS = "REQUEST_MI_REPORT_EMAILS",
  SERVICE_ANALYTICS = "SERVICE_ANALYTICS",
  REQUEST_SETTINGS = "REQUEST_SETTINGS",
  REQUEST_PARTICIPANTS = "REQUEST_PARTICIPANTS",
  SOFTWARE_STATEMENTS = "SOFTWARE_STATEMENTS",
  REQUEST_DCR_BULK_AUDIT = "REQUEST_DCR_BULK_AUDIT",
  REQUEST_DCR_BULK_AUDITS = "REQUEST_DCR_BULK_AUDITS",
  MANUAL_DCR_REGISTRATION = "MANUAL_DCR_REGISTRATION",
  RESPONSE_PARTICIPANTS = "RESPONSE_PARTICIPANTS",
  RESPONSE_PARTICIPANTS_BY_ORGID = "RESPONSE_PARTICIPANTS_BY_ORGID",
  SOFTWARE_STATEMENT_INFO_BY_ORGID = "SOFTWARE_STATEMENT_INFO_BY_ORGID",
  RESPONSE_SLA_REPORT = "RESPONSE_SLA_REPORT",
  REQUEST_MI_REPORT_SCHEDULER = "REQUEST_MI_REPORT_SCHEDULER",
  SETTINGS_ALERTS = "SETTINGS_ALERTS",
  ALERT_HISTORY = "ALERT_HISTORY",
  DCR_SETTINGS = "DCR_SETTINGS",
  UPDATE_AUTH_SERVER = "UPDATE_AUTH_SERVER",
}

export type TRequestPermissionLevels =
  | "Administration"
  | "Transaction History"
  | "MI Report Access"
  | "Participant Management"
  | "ITSM"
  | "Upload Vocalink File"
  | "Upload Participant File"
  | "CoP Check"
  | "No Access"
  | "Internal"
  | "External";

export type TResponsePermissionLevels =
  | "Administration"
  | "Transaction History"
  | "Participant Management"
  | "ITSM"
  | "Account Management"
  | "No Access"
  | "Internal"
  | "External";

export type Attributes =
  | "admin_request_permission"
  | "admin_response_permission"
  | "developer_type"
  | "pwd_changed"
  | "pwd_change_req_attempts"
  | "pwd_change_req_timestamp"
  | "is_email_verified"
  | "apps";

export type DeveloperTypes = "admin" | "internal" | "external";

export enum UPLOAD_FILE {
  BATCH_PAYEE = "BATCH_PAYEE",
  ACCOUNT_PERSONAL = "ACCOUNT_PERSONAL",
  ACCOUNT_BUSINESS = "ACCOUNT_BUSINESS",
}

export enum Routes {
  SIGN_UP = "/register",
  HOME = "/",
  GAME = "/name-matching-game",
  DOCUMENTATION = "/documentation",
  REGISTER = "/register",
  PRODUCTS = "/products",
  CONTACT_US = "/contact",
  FAQ = "/faq",
  PRIVACY = "/privacy-policy",
  TERMS = "/terms-and-conditions",
  USER = "/user",
  APPS = "/user/apps",
  PROFILE = "/user/profile",
  ADMIN = "/admin",
  ADMIN_PROFILE = "/admin/profile",
  DASHBOARD = "/admin/dashboard",
  REQUEST = "/admin/request",
  REQUEST_CONFIRM_PAYEE = "/admin/request/payee",
  REQUEST_CONFIRM_SINGLE_PAYEE = "/admin/request/payee/single",
  REQUEST_CONFIRM_SINGLE_PAYEE_TRANSACTIONS = "/admin/request/payee/single/transactions",
  REQUEST_CONFIRM_BATCH_PAYEE = "/admin/request/payee/batch",
  REQUEST_TRANSACTION_HISTORY = "/admin/request/transactions",
  REQUEST_MI_REPORT = "/admin/request/reports",
  REQUEST_GLOBAL_MI_REPORT = "/admin/request/mi-reports",
  REQUEST_GLOBAL_MI_REPORT_SETTINGS = "/admin/request/mi-reports/settings",
  REQUEST_SETTINGS = "/admin/request/settings",
  REQUEST_SETTINGS_MI_REPORT = "/admin/request/settings/mi-report",
  REQUEST_SETTINGS_VOCALINK_API = "/admin/request/settings/vocalink-api",
  REQUEST_SETTINGS_VOCALINK_SHARE = "/admin/request/settings/vocalink-share",
  REQUEST_SETTINGS_UPLOAD = "/admin/request/settings/upload",
  REQUEST_SETTINGS_NAME_MATCHING = "/admin/request/settings/name-matching",
  REQUEST_PARTICIPANTS = "/admin/request/participants",
  REQUEST_PARTICIPANTS_MANUAL_DCR = "/admin/request/participants/manual-dcr",
  RESPONSE = "/admin/response",
  RESPONSE_SERVICE_HISTORY = "/admin/response/transactions",
  RESPONSE_SERVICE_ACCOUNTS = "/admin/response/accounts",
  RESPONSE_SERVICE_ACCOUNTS_PERSONAL = "/admin/response/accounts/personal",
  RESPONSE_SERVICE_ACCOUNTS_PERSONAL_UPLOAD_HISTORY = "/admin/response/accounts/personal/account-upload-history",
  RESPONSE_SERVICE_ACCOUNTS_BUSINESS = "/admin/response/accounts/business",
  RESPONSE_SERVICE_ACCOUNTS_BUSINESS_UPLOAD_HISTORY = "/admin/response/accounts/business/account-upload-history",
  RESPONSE_SERVICE_SETTINGS = "/admin/response/settings",
  RESPONSE_SERVICE_SETTINGS_HONORIFICS = "/admin/response/settings/honorifics",
  RESPONSE_SERVICE_SETTINGS_DESCRIPTORS = "/admin/response/settings/descriptors",
  RESPONSE_SERVICE_SETTINGS_LEVENSHTEIN = "/admin/response/settings/levenshtein",
  RESPONSE_SERVICE_REPORTS = "/admin/response/reports",
  RESPONSE_SERVICE_PARTICIPANTS = "/admin/response/participants",
  SETTINGS = "/admin/settings",
  USER_MANAGEMENT = "/admin/settings/users",
  PERFORMANCE_METRICS = "/admin/settings/performance",
  ALERTS = "/admin/settings/alerts",
  PARTICIPANTS_CACHE = "/admin/settings/cache",
}

export interface IPagination {
  pageIndex: number;
  pageSize: number;
  pageTotal: number;
  total: number;
}

export interface IAdminUserResponse {
  users: IUser[];
  total: number;
}

export interface IUserFilter {
  searchValue: string;
  permission: string | null;
  userType: string;
  active: boolean;
  inActive: boolean;
}

export interface ICloseRequestValues {
  country: CountryCode;
  id: string;
  userId: string;
}

export interface IConfirmPayeeNL {
  accountId: {
    value: string;
    type: string;
  };
  name: string;
  accountType: string;

  userId?: string;
  userType?: string;
  clientId: string;
}

export type AccountStatusUnion =
  | "Open"
  | "Closed"
  | "ACTIVE"
  | "Active"
  | "INACTIVE"
  | "Inactive"
  | "CLOSED"
  | "Blocked"
  | "Forbidden";

export type ResponseCodes =
  | "ANNM"
  | "MBAM"
  | "BANM"
  | "PANM"
  | "BAMM"
  | "PAMM"
  | "IVCR"
  | "ACNS"
  | "OPTO"
  | "CASS"
  | "SCNS"
  | "AC01"
  | "BE05"
  | "BE15"
  | "BE21"
  | "FF01"
  | "RC11"
  | "01000"
  | "01001"
  | "01010"
  | "01030"
  | "01040"
  | "02000"
  | "02001"
  | "02020"
  | "03000"
  | "03001"
  | "03020"
  | "04000"
  | "04001"
  | "04002"
  | "04003"
  | "04020"
  | "05000"
  | "05001"
  | "05020"
  | "06000"
  | "06001"
  | "06020"
  | "07000"
  | "07001"
  | "07020"
  | "08000"
  | "08001"
  | "08020"
  | "11000"
  | "11001"
  | "11020";
export type ConfirmPayeeCommonReturn = {
  result: {
    data: {
      result: {
        id: string;
        account: {
          id: string;
          status: AccountStatusUnion;
          respondedName?: string;
          nameCheckStatus: Match;
        };
        responder: {
          accountVerificationScheme: string;
          apiVersion: string;
          receivedTime: string;
          data: string;
          schemeResponseCodes: ResponseCodes[];
          entity?: {
            idType: string;
            idValue: string;
            reference: string;
          };
        };
      };
    };
  };
};
export type ConfirmPayeeReturn = {
  result: {
    Data: {
      VerificationReport: {
        Name: string;
        Code: string;
        Matched: boolean;
        ReasonCode: string;
        TransactionAllowed: boolean;
      };
    };
    transactionId: string;
  };
};
export type ConfirmPayeeNLReturn = {
  result: {
    nameMatchResult: string;
    dataUsedForMatching: string;
    account: {
      accountNumberValidation: string;
      paymentPreValidation: string;
      status: string;
      accountTypeMatchResult: string;
      jointAccount: boolean;
      numberOfAccountHolders: number;
      countryCode: string;
    };
    scheme: string;

    transactionId: string;
  };
};

export interface IApp {
  clientId: string;
  name: string;
  clientSecret: string;
  product: IProduct;
  url: string;
  analyticsId: string;
  appId: string;
}
export interface IProduct {
  title: string;
}

export interface TokenExchangeData {
  id_token: string;
  access_token: string;
  refresh_token: string;
}

export interface SessionData extends TokenExchangeData {
  timestamp: number;
  adminSession?: boolean;
}

export interface IUserAttribute {
  value: TRequestPermissionLevels | TResponsePermissionLevels | "admin" | (string & {});
  type: Attributes;
}

export interface IUser {
  accountId: string;
  name: string;
  username: string;
  email: string;
  organization: string;
  contactPerson: string;
  contactNumber: string;
  developerType: DeveloperTypes;
  firstName: string;
  lastName: string;
  userAttributes: IUserAttribute[];

  // Admin User
  id: string;
  active?: boolean;
  activated?: string;
  apps: string[];
  emailVerified?: boolean;
  createdDate?: string;
  modifiedDate?: string;

  pwdChanged: number;
  pwdChangeRequestAttempts: number;
  pwdChangeRequestTimestamp: number;
}

export enum ACCOUNT_TYPES {
  PERSONAL = "Personal",
  BUSINESS = "Business",
}

export enum SETTING_TYPES {
  HONORIFICS = "valid_honorifics",
  LEGAL_DESCRIPTORS = "valid_legal_descriptors",
}

export enum AlertTabs {
  CONFIG = "config",
  NOTIFICATION = "notification",
  HISTORY = "history",
}

export enum UserTypes {
  ADMIN = "admin",
  DEVELOPER = "developer",
}

export interface IPersonalAccountAttribute {
  honorific?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  preferredName?: string;
  accountName: string;

  cityOfBirth?: string;
  countryOfBirth?: string;
  dateOfBirth?: string;

  version?: number;
}
export interface IAccount {
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  createOperationType: string;
  lastModifiedOperationType: string;
  sortCode: string;
  bic: string;
  iban: string;
  accountStatus: AccountStatusUnion;
  restrictedCode: string;
  accountNumber: string;
  accountName: string;
  accountRef: string;
  accountLevelSRD: boolean;
  firstName: string;
  lastName: string;
  jointAccount: boolean;
  companyName: string;
  payeeName: string;
  tradingName: string;
  abbreviatedName: string;
  accountType: "Personal" | "Business" | "PERSONAL" | "BUSINESS";
  accountTypeSupported: boolean;
  identities: {
    DOB: string;
    NTNL: string;
    ID: string;
    LEI: string;
    VAT: string;
    TXID: string;
    AnyBIC: string;
  };
  personalAccountAttribute: IPersonalAccountAttribute[];
  personalAccountAttributes: IPersonalAccountAttribute[];
  businessAccountAttributes: {
    iban: string;
    bic: string;
    abbreviatedName: string;
    accountName: string;
    brandingName: string;
    accountNumber: string;
    Vat: string;
    Tin: string;
    siren: string;
    siret: string;
    vat: string;
    holdingCompanyName: string;
    invoiceDiscountingAccountIndicator: boolean;
    tradingName: string;
    registeredName: string;

    version?: number;
  };
  isActive: boolean;
  active: boolean;
  excluded: boolean;
  inScope: boolean;

  registeredName: string;
  siren: string;
  siret: string;
  vat: string;

  version?: number;
}
export interface IGetAccountsResponse {
  result: IAccount[];
  content: IAccount[];

  pagination: IPagination;
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export type IAccountUploadHistory = {
  id: number;
  batchId: string;
  createdDate: string;
  fileName: string;
  userId?: string;
  status: "ERROR" | "SUCCESS" | "IN_PROGRESS";
  fileUploadMethod: "sftp" | "portal_api";
  noOfAccounts: number;
};

export type IGetAccountsUploadHistoryResponse = {
  result: IAccountUploadHistory[];
  pagination: IPagination;
};

export enum AccountStatus {
  AC00 = "AC00",
  AC01 = "AC01",
  AC05 = "AC05",
  AC99 = "AC99",
  RJCT = "RJCT",
}

export type AccountStatusMap = "AC00" | "AC01" | "AC05" | "AC99" | "RJCT";

export type RequestStatusTypes = "SUCCESS" | "IN_PROGRESS" | "FAIL" | "INVALID" | "VALID";

export type TransactionStatusTypes =
  | "INQ_CRTD"
  | "INQ_RESP"
  | "RESP_CONF"
  | "INQ_CLOSED"
  | "INQ_RJCT"
  | "RESP_RJCT_CONF";

export type Match = "FULL_MATCH" | "PARTIAL_MATCH" | "NO_MATCH" | "UNABLE_TO_MATCH" | "ACCOUNT_NOT_FOUND";

export type Response = "FAIL" | "INCO" | "PASS" | "WARN";
export type NameMatch = "MTCH" | "MBAM" | "NMTC" | "NOAP" | "NOTC";
export type AddressMatch = "MTCH" | "NMTC" | "NOAP" | "NOTC";

export interface IJPMRequestServiceAudit {
  pagination: IPagination;
  result: IJPMRequestAudit[];
}

export interface IJPMRequestAudit {
  Id: string;
  AccountId: string;
  AccountIdType: string;
  AccountStatus: AccountStatus;
  BankId: string;
  BankIdType: string;
  ClientId: string;
  InquiryReferenceId: string;
  RequestName?: string;
  RequestStatus: RequestStatusTypes;
  RequestedOrgName: string;
  RequestTransactionStatus: TransactionStatusTypes;
  RequestTimeIssued: string;
  ResponseName?: string;
  ResponseReasonCode: Match;
  ResponseMatched: boolean;
  ResponseTimeReceived: string;
  TransactionNotes: TransactionNote[];
  NationalId?: string;
  UserId: string;
  UserType: string;

  partyTypeValidated?: string;
  agentBank?: string;
  RvcrIdType?: string;
  RvcrIdValue?: string;
  AccountHolderIdType?: string;
  AccountHolderId?: string;
  RejectedTimeReceived?: string;
  rejectionReason?: string;
  ClosureTimeReceived?: string;
  responseConfirmed?: string;
  responseParticipant?: string;
  responseParticipantId?: string;
}

export interface TransactionNote {
  index: number;
  transactionId: string;
  note: string;
  createdDate: string;
  createdBy: string;
  firstName: string;
  lastName: string;
}

export interface IRequestServiceAudit {
  pagination: IPagination;
  result: IRequestAudit[];
}
export type ResponseReasonCode =
  | "ANNM"
  | "MBAM"
  | "BANM"
  | "PANM"
  | "BAMM"
  | "PAMM"
  | "AC01"
  | "IVCR"
  | "ACNS"
  | "OPTO"
  | "CASS"
  | "SCNS";
export type AccountStatusCode = "AC00" | "AC05" | "AC99" | "RJCT";
export interface IRequestAudit {
  Id: string;
  AccountStatus: string;
  IBAN?: string;
  TransactionNotes: TransactionNote[];
  RequestSource: string;
  RequestStatus: RequestStatusTypes;
  BatchId: string;
  RequestedCopEpUrl: string;
  RequestAccountType: string;
  RequestIdentification: string;
  RequestSecondaryIdentification?: string;
  RequestName?: string;
  RequestedOrgId: string;
  RequestedOrgName: string;
  RequestSchemeName: string;
  RequestTimeIssued: string;
  ResponseName?: string;
  ResponseMatched?: boolean;
  ResponseReasonCode?: ResponseReasonCode;
  ResponseTimeReceived?: string;
  Bic?: string;
  AccountNumber?: string;
  ClientId: string;
  UserId: string;
  UserType: string;
  batchRequestTime: string;
  batchId: string;
  totalRequests: string;
  successCount: string;
  inProgressCount: string;
  failureCount: string;
  uniqueBatchId: string;
  preprocessingFailed: boolean;
  preProcessing: boolean;
  Properties: IResponseAuditProperty[];
  ErrorMessage: string;
  AccountXRefID: string;
}

export type CountryCodesCommon = CountryTypes;
export type VerificationTypes = keyof typeof validationTypeEnum;
export interface IRequestServiceAuditCommon {
  content: IRequestAuditCommon[];

  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
}
export interface IRequestAuditCommon {
  id: string;
  country: CountryCodesCommon;
  verificationType: VerificationTypes[];
  idSchemeType: "IBAN" | "ACNR" | "CUID" | "UPIC" | "SCAN";
  entityType?: string;
  entityValue?: string;
  accountId: string;
  accountHolderName: string;
  accountStatusCode?: AccountStatusCode;
  pspId?: string;
  pspIdType?: string;
  userId: string;
  userType: string;
  clientId?: string;
  copEndpointUrl: string;
  nameMatched: boolean;
  payeeBankName: string;
  requestStatus?: string;
  requestCreatedDate: string;
  responseName: string;
  identitiesResultTypeList?: {
    status: "FULL_MATCH" | "PARTIAL_MATCH" | "NO_MATCH" | "UNABLE_TO_MATCH";
    type: "SIREN" | "SIRET" | "VAT" | "DOB";
    value?: string;
  }[];
  identitiesTypeList?: { type: "SIREN" | "SIRET" | "VAT" | "DOB"; value?: string }[];
  responseReasonCode?: ResponseReasonCode;
  responseCreatedDate?: string;
  rejectionReasonCode?: ResponseCodes;
  schemeResponseCodes?: ResponseCodes[];
  transactionNotes?: TransactionNote[];
  rvcrIdType?:
    | "SWIFT Id"
    | "BICFI"
    | "IBAN"
    | "ATBLZ"
    | "AUBSB"
    | "CACPA"
    | "CHBCC"
    | "CHSIC"
    | "CNAPS"
    | "CNCIP"
    | "DEBLZ"
    | "ESNCC"
    | "GBDSC"
    | "GRBIC"
    | "HKNCC"
    | "IENCC"
    | "INFSC"
    | "ITNCC"
    | "KRBOK"
    | "JPZGN"
    | "MZBMO"
    | "NZNCC"
    | "NZRSA"
    | "PLKNR"
    | "PTNCC"
    | "RUCBC"
    | "SESBA"
    | "SGIBG"
    | "THCBC"
    | "TWNCC"
    | "USABA"
    | "USPID"
    | "ZANCC"
    | "ICQX"
    | "IC"
    | "VAT"
    | "BIC"
    | "BANK"
    | "CBID"
    | "CHID"
    | "CINC"
    | "CUST"
    | "DUNS"
    | "EMPL"
    | "GS1G"
    | "SREN"
    | "SRET"
    | "TXID"
    | "BDID"
    | "BOID"
    | "LEI"
    | "KVK"
    | "ARNU"
    | "CCPT"
    | "DRLC"
    | "NIDN"
    | "SOSE"
    | "TELE"
    | "POID"
    | "DOFB"
    | "CLABE";
  rvcrIdValue?: string;
  requestTransactionStatus?: TransactionStatusTypes;
  bankIdType?: string;
  bankIdValue?: string;
  nameMatchStatus: "FULL_MATCH" | "PARTIAL_MATCH" | "NO_MATCH" | "UNABLE_TO_MATCH" | null;
  accountStatus:
    | "PENDING"
    | "ACTIVE"
    | "INACTIVE"
    | "CLOSED"
    | "FORBIDDEN"
    | "UNABLE_TO_MATCH"
    | "NOT_FOUND"
    | "UNKNOWN"
    | null;
  idStatus: "FULL_MATCH" | "PARTIAL_MATCH" | "NO_MATCH" | "UNABLE_TO_MATCH" | null;
  idType: string;
  idValue: string;
  nameReasonCode: string;
  idMatchStatus: "FULL_MATCH" | "PARTIAL_MATCH" | "NO_MATCH" | "UNABLE_TO_MATCH" | null;
  idReasonCode?: string;
}

export type StatusCodeNordic =
  | "PN01"
  | "PN02"
  | "AC01"
  | "AG01"
  | "NR01"
  | "FF01"
  | "NORR"
  | "DS28"
  | "RC06"
  | "RC07"
  | "TM01"
  | "BNOR"
  | "MS03";
export interface IRequestAuditNordic {
  id: string;
  clientId: string;
  messageIdentification: string;
  requestTimeReceived: string;
  requestType: string;
  identificationType?: string;
  identificationValue?: string;
  iban: string;
  bic: string;
  requestAccountType?: string;
  responseTimeSent: string;
  responseMatched: boolean;
  responseReasonCode?: StatusCodeNordic;
  payeeBankName: string;
  partcipantUrl: string;
  userId: string;
  userType: string;

  requesterMessageIdentification: string;
  requestIdentification: string;
  payUkCopRegistrationId?: string;
  requestName: string;
  requestSchemeName: string;
  requestStatus: string;
  requestSource?: string;
  requestTimeIssued: string;
  requestedCopEpUrl?: string;
  requestedOrgId?: string;
  requestedOrgName?: string;
  requestSecondaryIdentification?: string;
  responseTimeReceived: string;
  slaResponse: boolean;
  transactionNotes: TransactionNote[];
}
export interface IRequestServiceAuditNordic {
  content: IRequestAuditNordic[];

  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export interface IRequestAuditFR {
  TransactionNotes: TransactionNote[];
  Id: string;
  RequestStatus: RequestStatusTypes;
  RequestAccountType: string;
  RequestAccountStatus: "Open" | "Closed" | "Account doesn't exists";
  IBAN: string;
  RequestName: string;
  RequestedOrgName: string;
  RequestTimeIssued: string;
  ResponseName?: string;
  ResponseMatched?: boolean;
  RejectionReasonCode?: string;
  ResponseReasonCodes?: string[];
  ResponseTimeReceived?: string;
  ClientId: string;
  UserId: string;
  UserType: string;
  totalRequests: string;
  successCount: string;
  inProgressCount: string;
  failureCount: string;
  OtherName: string;
  PassportNumber: string;
  PassportDoi: string;
  NationalId: string;
  NationalDoi: string;
  DateOfBirth: string;
  CityOfBirth: string;
  CountryOfBirth: string;
  Siren: string;
  Siret: string;
  Vat: string;
  TransactionAllowed?: boolean;
  OverAllResponseStatus?: string;
}

export interface IRequestServiceAuditFR {
  pagination: IPagination;
  result: IRequestAuditFR[];
}

export enum REQUEST_TYPES {
  BATCH = "BATCH",
  SINGLE = "SINGLE",
}

export interface IResponseAuditProperty {
  name: string;
  value: string;
}

export interface IResponseAudit {
  Id: string;
  IsPayeeAccountFound: boolean;
  IsPayeeAccountRestricted: boolean;
  PayeeAccountType: string;
  RequestAccountType: string;
  RequestIdentification: string;
  RequestName: string;
  RequestedOrgName: string;
  RequestOrgId: string;
  RequestSchemeName: string;
  RequestSecondaryIdentification: string;
  RequestTimeReceived: string;
  ResponseName?: string;
  ResponseMatched: boolean;
  ResponseReasonCode: string;
  ResponseTimeSent: string;
}
export interface IResponseSWAudit {
  Id: string;
  RequestTimeReceived: string;
  ResponseMatched: boolean;
  ResponseReasonCode: string;
  ResponseSentTime: string;
  OverAllResponse: string;
  IsRestrictedAccount: string;
  CreditorAccount: string;
  CreditorName: string;
  RequestedCreditorName: string;
  ActualCreditorName: string;
  AccountMatchResponse: string;
  NameMatchResponse: NameMatch;
  AddressMatchResponse: AddressMatch;
  CreditorIdentificationMatchResponse: AddressMatch;
  CorrelationIdentifier: string;
  AccountType: string;
  ValidationSource: string;
  StreetName: string;
  BuildingName: string;
  PostCode: string;
  TownName: string;
  Country: string;
}
export interface IResponseSWServiceAudit {
  pagination: IPagination;
  result: IResponseSWAudit[];
}
export interface IResponseServiceAudit {
  pagination: IPagination;
  result: IResponseAudit[];
}

export interface IResponseAuditNordic {
  id: string;
  bic: string;
  iban: string;
  requestTimeReceived: string;
  requesterMessageIdentification: string;
  messageIdentification: string;
  requestType: string;
  requestName?: string;
  identificationType: string;
  identificationValue: string;
  requestAccountType: string;
  requestedOrgName: string;
  responseTimeSent: string;
  responseMatched: boolean;
  responseReasonCode?: StatusCodeNordic;
}

export interface IResponseServiceAuditNordic {
  content: IResponseAuditNordic[];

  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export interface IResponseAuditEPC {
  id: string;
  bic?: string;
  iban: string;
  requestTimeReceived: string;
  accountID: "IBAN";
  requestName?: string;
  responseName: string;
  identities: { LEI?: string; NIDN?: string };
  requestedOrgName: string;
  responseTimeSent: string;
  responseMatchName: string;
  responsePartyNameMatch?: "MATCH" | "NO_MATCH" | "PARTIAL_MATCH" | "UNABLE_TO_MATCH";
  responseNameMatchCode?: NameMatch;
  responsePartyIdMatch?: "MATCH" | "NO_MATCH" | "PARTIAL_MATCH" | "UNABLE_TO_MATCH";
  responseIdMatchCode?: NameMatch;
}

export interface IResponseServiceAuditEPC {
  content: IResponseAuditEPC[];

  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export type ParticipantStatus = "ACTIVE" | "NOT_REGISTERED" | "SUSPENDED" | "ERROR" | "RECENTLY_FAILED";

export interface IRequestServiceParticipants {
  content: IRequestParticipant[];

  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export interface IRequestParticipant {
  ClientId?: string;
  ClientName?: string;
  DcrTime?: string;
  ResponderOrganisationId: string;
  ResponderOrganisationName: string;
  ResponderAuthServerId: string;
  ResponderAuthServerName: string;
  Status: ParticipantStatus;
  RecentlyFailedTime?: string;
  DirectoryType?: DirectoryTypes;
}

export interface IResponseServiceParticipants {
  content: IResponseParticipant[];

  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export interface IResponseParticipant {
  RequesterOrganizationId: string;
  RequesterOrganizationName: string;
  AuthServerId?: string;
  AuthServerName?: string;
  SuccessfulRegistration: number;
  LatestSuccessfulDcrTime?: string;
  LatestDcrTime: string;
  Status: ParticipantStatus;
  DirectoryType: DirectoryTypes;
}

export interface INameMatching {
  createdBy: string;
  createdDate: Date;
  id: string;
  descriptors: INameMatchingDescriptors[];
}
export type LevenshteinDistanceConfig = {
  id: string;
  fnExactMatch: number;
  fnPartialMatch: number;
  lnExactMatch: number;
  lnPartialMatch: number;
  anExactMatch: number;
  anPartialMatch: number;
  bnExactMatch: number;
  bnPartialMatch: number;
};

export interface INameMatchingDescriptors {
  type: string;
  value: string;
}

export interface IAdminUserFormValues {
  userName: string;
  firstName: string;
  lastName: string;
  organization: string;
  password: string;
  requestPermissions: string[];
  responsePermissions: string[];
}

export enum DialogTypes {
  CREATE = 1,
  CREATE_DCR,
  UPDATE,
  UPDATE_DCR,
  REMOVE,
}

export enum AccountDialogTypes {
  CREATE = 1,
  UPDATE,
  REMOVE,
}

export enum UserDialogTypes {
  CREATE = 1,
  UPDATE,
  REMOVE,
  VIEW,
  ACTIVATE,
}

export enum ChartType {
  Usage = "Usage",
  StatusCodes = "StatusCodes",
}

export interface IPeriod {
  granularity: string;
  name: string;
  value: number;
  labelCount: number;
  since: string;
  until: string;
}
export interface IDate {
  text?: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
}

export type IGranularity = "hour" | "day" | "month";

export interface IChartData {
  details: {
    metric: string;
    granularity: IGranularity;
    until: string;
    since: string;
    name: string;
  };
  total: number;
  values: number[];
  /*metric?: { id: number; name: "Hits"; system_name: "hits"; unit: "hit" };
  response_code?: { code: "2XX" | "4XX" | "5XX" };
  period: {
    name: string | null;
    since: string;
    until: string;
    timezone: string;
    granularity: IGranularity;
  };
  total: number;
  values: number[];
  application: {
    id: number;
    name: string;
    state: string;
    description: string | null;
    plan: { id: number; name: string };
    account: { id: number; name: string };
    service: { id: number };
  };*/
}

export interface ICommonAnalytics {
  verificationStatusReport: {
    NO_MATCH: number;
    FULL_MATCH: number;
    UNABLE_TO_MATCH: number;
    PARTIAL_MATCH: number;
  };
  verificationTypeReport: {
    STATUS: number;
    NAME: number;
    ID: number;
  };
  holderTypeReport: {
    BUSINESS: number;
    PERSONAL: number;
    OTHERS: number;
  };
  countryCodeReport: {
    [key in CountryCodesCommon]?: number;
  };
  topBanksReport: {
    [key in CountryCodesCommon]?: {
      country: key;
      bankName: string;
      count: number;
    }[];
  };
}
export interface IConfirmationAnalytics {
  OverallConfirmations: number;
  SingleConfirmations: number;
  BatchConfirmations: number;
  TrueMatchCount: number;
  ResponseReasonCodesCount: IResponseReasonCodesCount;
  FailedNameVerificationsCount: number;
  RespondingBanksCount: ICountByBanks[];
}
export interface ISwiftRequestAnalytics {
  BusinessOverAllSuccessCount: number;
  PersonalOverAllSuccessCount: number;
  OverAllFailureCount: number;
  BusinessOverAllWarnCount: number;
  PersonalOverAllWarnCount: number;
  BusinessOverAllIncoCount: number;
  PersonalOverAllIncoCount: number;
  BusinessAccountMatchCount: number;
  PersonalAccountMatchCount: number;
  AccountNoMatchCount: number;
  BusinessNameMatchCount: number;
  PersonalNameMatchCount: number;
  BusinessNamePartialMatchCount: number;
  PersonalNamePartialMatchCount: number;
  BusinessNameNoMatchCount: number;
  PersonalNameNoMatchCount: number;
  BusinessNameMatchNotPerformedCount: number;
  PersonalNameMatchNotPerformedCount: number;
  NameMatchNotApplicableCount: number;
  OrgIdMatchCount: number;
  OrgIdNoMatchCount: number;
  OrgIdNotPerformedCount: number;
  OrgIdMatchNotApplicableCount: number;
}
export interface INordicRequestAnalytics {
  TrueMatchCount: number;
  ResponseReasonCodesCount: {
    FF01: number;
    NORR: number;
    DS28: number;
    RC06: number;
    RC07: number;
    TM01: number;
    BNOR: number;
    MS03: number;
    AC01: number;
    AG01: number;
    PI01: number;
    PN01: number;
    PN02: number;
    NR01: number;
  };
  CarCount: number;
  CprCount: number;
}

export interface IJPMRequestAnalytics {
  inquiryStatusMetrics: {
    inquiryCreatedCount: number;
    inquiryRespondedCount: number;
    inquiryResolvedCount: number;
    forceClosedCount: number;
    inquiryRejectedCount: number;
    responseRejectedCount: number;
  };
  accountStatusMetrics: {
    ac01Count: number;
    ac00Count: number;
    ac05Count: number;
    ac99Count: number;
    rjctcount: number;
  };
  nameMatchingMetrics: {
    fullMatchCount: number;
    noMatchCount: number;
    partialMatchCount: number;
    accountNotFoundCount: number;
    unableToMatchCount: number;
  };
  requestQueryMetrics: {
    nmCount: number;
    acctStatusCount: number;
    acctTrnActivityCount: number;
  };
}

export interface IResponseReasonCodesCount {
  ANNM: number;
  MBAM: number;
  BANM: number;
  PANM: number;
  BAMM: number;
  PAMM: number;
  AC01: number;
  IVCR: number;
  ACNS: number;
  OPTO: number;
  CASS: number;
  SCNS: number;
}

export interface ICountByBanks {
  Name: string;
  Count: number;
}

export type Order = "asc" | "desc";

export enum CountryCode {
  UK = "uk",
  NL = "nl",
  FR = "fr",
  BF = "bf",
  EPC = "epc",
  BR = "br",
  IN = "in",
  ID = "id",
  DE = "de",
  SW = "sw",
  MX = "mx",
  NP = "np",
  NG = "ng",
  PK = "pk",
  VN = "vn",
  US = "us",
  GLOBAL = "global",
}

export type ServiceTypes = "request" | "response";

export interface ISlaReport {
  availability: string;
  since: string;
  volume: number;
  responseTime: number;
  errorRate: number;
  granularity: IGranularity;
}

export interface ISlaReportResponse {
  result: ISlaReport[];
  pagination: IPagination;
}

export interface ISoftwareStatement {
  SoftwareStatementId: string;
  SoftwareStatementName: string;
  DirectoryType?: DirectoryTypes;

  RegisteredDate: string;
  OrganisationId: string;
  DcrRequestStatus: "SUCCESS" | (string & {});
  RequestMethodType: "CREATE" | (string & {});
}
export interface IClientRegistrationValues {
  userId: string;
  SoftwareId: string;
  SsaJwt?: string;
  AuthServers: ISoftwareStatement["SoftwareStatementId"][];
}

export interface IDcrSettingsUpdateValues {
  cacheSuccess: boolean;
  interval: number;
  time: string;
  dcrRequestEnable: boolean;
  retries: number;
  suspend: number;
  lastTriggerTime: string;
  nextScheduledTime: string;
  retryEnabled: boolean;
  suspendEnabled: boolean;
}

export interface IUpdateAuthServerValues {
  status: string;
  authServerId: string;
}

export interface IDCRBulkAudits {
  batchId: string;
  dcrTime: string;
  completedCount: number;
  errorCount: number;
  totalAuthServerCount: number;
  requestMethodType: "CREATE" | (string & {});
  userId: string;
  ssaUsed: string;
  status: "IN_PROGRESS" | "COMPLETED";
}

export interface IResponseServiceDCRBulkAudits {
  content: IDCRBulkAudits[];

  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export interface IDCRRegistrationAudit {
  batchId: string;
  dcrJobId: string;
  authServerId: string;
  authServerName: string;
  organisationId: string;
  organisationName: string;
  registrationTime: string;
  requestMethodType: "CREATE" | (string & {});
  softwareStatementId: string;
  softwareStatementName: string;
  status: "SUCCESS" | "ERROR" | "IN_PROGRESS" | "FAILED";
}

export interface IDCRAudits {
  id: string;
  registrationTime: string;
  registrationStatus: string;
  registrationType: string;
  requestMethodType: "CREATE" | (string & {});
  userId: string;
  dcrJobId: string;
  organisationId: string;
  organisationName: string;
  authServerId: string;
  authServerName: string;
  clientId: string;
  clientName: string;
  responderWellKnownEndpoint: string;
  responderClientAuthenticationType: string;
  responderRoutingIds: string[];
  requesterSsaID: string;
  requesterSsaVersion: string;
  jwtUsed: string;
  errorCode: string;
  errorMessage: string;
  responderContactDetails: IResponderContacts[];
}

export interface IResponderContacts {
  email: string;
  id: number;
  name: string;
  obEntityId: string;
  phone: string;
  type: string;
}

export interface IDCRAuditsResponse {
  content: IDCRAudits[];

  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}
export interface IParticipantHistory {
  RequesterOrganizationId: string;
  RequestMethodType: string;
  DcrRequestTime: string;
  SsaSoftwareClientId: string;
  SsaJwt: string;
  SsaClientName: string;
  SsaVersion: string;
  TechnicalContact: string;
  BusinessContact: string;
  ClientId: string;
  ClientAuthenticationType: string;
  Status: "SUCCESS" | "FAIL";
  ErrorCode: string;
  ErrorDescription: string;
}
export interface IParticipantHistoryResponse {
  content: IParticipantHistory[];

  number: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export type NotificationAlerts = {
  id: number;
  emails: string[];
  rules: {
    ruleType: "xRequestsInYMinutes" | "xRequestsInYDays";
    numberOfRequests: number;
    numberOfMinutes: number;
    numberOfDays: number;
    percentageDifference: number;
    varyingValuePropertyName: string;
    sameValuePropertyName: string;
  }[];
};

export type AlertHistory = {
  id: string;
  ruleName: string;
  ruleDescription: string;
  requestsDifference: string;
  intervalStart: string;
  intervalEnd: string;
  emailIDs: string[];
  emailStatus: "SUCCESS" | (string & {});
  emailSentTime: string;
};

export interface AlertHistoryResponse {
  result: AlertHistory[];
  pagination: IPagination;
}
